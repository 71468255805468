/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserRole } from '../../redux/actions';

class AdminRoute extends Component {

  componentDidMount() {
    this.props.fetchUserRole();
  }

  renderMergedProps = (component, rest) => React.createElement(component, rest);

  render() {
    const { component, role, parentRoute, allowedRoles, ...rest } = this.props;
    return (
      <Route {...rest} render={(props) => (allowedRoles) ? (
        this.renderMergedProps(component, {...props, ...rest, role: role})
      ) : (
        <Redirect to={parentRoute} />
      )} />
    )
  }
}

AdminRoute.propTypes = {
  component: PropTypes.func.isRequired,
  fetchUserRole: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  parentRoute: PropTypes.string.isRequired,
  allowedRoles: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  role: state.auth.role,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserRole: () => dispatch(getUserRole()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoute);
