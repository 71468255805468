import styled, {css} from 'styled-components';
const MediumFont = 'Rubik-Regular';
const BoldFont = 'Rubik-Medium';
import {CustomTextArea, TitleContainer} from "../CreateEvent/styles";
import {CustomInput} from "../CreateChallengeForm/styles";
import { Flex, OptionCircle } from '../ArticleQuiz/styles';
const MainContianer = styled.div`
  width: 100%;
  float: left;
  padding: 30px;
`;
const SideMonthsContianer = styled.div`
  width: 20%;
  float: left;
  background: #FFFFFF;
  padding: 20px 20px 20px 40px;
  @media (max-width: 1138px){
    width: 25%;
  }
  @media (max-width: 730px){
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;
const CreateTasksContianer = styled.div`
  width: 80%;
  float: left;
  padding: 20px;
  @media (max-width: 1138px){
    width: 75%;
  }
  @media(max-width: 730px){
    width: 100%;
    padding: 20px 0;
  }
`;
const TasksContianer = styled.div`
	padding: ${({padding}) => padding};
  width: ${({width}) => width ? width : '100%'};
  float: left;
  >div:last-child{
    width: 100%;
    float: left;
    margin: 20px 0;
  }
`;
const Month = styled.button`
  width: 100%;
  text-align: left;
  color: #A7A8B1;
  font-size: 19px;
  line-height: 60px;
  font-family: ${MediumFont};
  outline: none;
  background: #FFFFFF;
  border: none;
  text-transform: uppercase;
  @media(max-width: 730px){
    width: calc(100% / 4);
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }
  @media(max-width: 600px){
    width: calc(100% / 3);
  }
  @media(max-width: 450px){
    width: calc(100% / 2);
    font-size: 17px;
    line-height: 25px;
  }
  &:first-child, &:last-child{
    color: #1E76AB;
    text-transform: capitalize;
  }
  ${({ active }) => active === true && css`
    color: #71A147;
    font-family:${BoldFont};
    &:first-child, &:last-child{
      color: #1E76AB;
      font-family: ${MediumFont};
    }
  `}
  &:hover, &:active, &:focus {
    color: #71A147;
    font-family:${BoldFont};
    &:first-child, &:last-child{
      color: #1E76AB;
      font-family: ${MediumFont};
    }
  }
`;
const TaskHeader = styled.div`
  font-family: ${BoldFont};
  color: #67676A;
  font-size: 28px;
  line-height: 38px;
  width: 100%;
  >div{
    opacity: 0.5;
    font-size: 16px;
    line-height: 22px;
    font-family: ${MediumFont};
  }
  @media(max-width: 500px){
    font-size: 18px;
  }
`;
const SingleTaskContainer = styled.div`
  width: 100%;
  float: left;
  border-bottom: ${({bottom}) => bottom ? 'none' : '3px solid rgba(0,0,0,0.4)'};

`;
const SaveTasks = styled.button`
  background: linear-gradient(0deg,#DE9444,#DE9444);
  border: none;
  outline: none;
  color: #FFFFFF;
  white-space: nowrap;
  padding: 10px 30px;
  font-size: 13px;
  border-radius: 25px;
  text-transform: uppercase;
  text-align: center;
  font-family: ${MediumFont};
  &:focus, &:active, &:hover {
    outline: none;
  }
  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
`;
const SingleTaskHeader = styled.div`
  color: #1E76AB;
  font-size: 22px;
  line-height: 30px;
  font-family: ${BoldFont};
`;
const TextArea = styled(CustomTextArea)`
 float: none;
 width: 100%;
 background: #fff;
 box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
 border: none;
 margin-top: 5px;
`;
const InputField = styled(CustomInput)`
 float: none;
 width: ${({ width }) => width ? width : '100%'};
 background: #fff;
 box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
 border: none;
 margin-top: 5px;
 @media (max-width: 1000px){
  width: 100%;
 }
`;
const IconContainer = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border: none;
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
`;
const IconDisplay = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  width: calc(100% / 11);
	height: 50px;
  div{

    >img{
    width: ${({width}) => width ? width : '25px'};
    height: 25px;
  }

  }
  >img{
    width: ${({width}) => width ? width : '25px'};
    height: 25px;
  }
  @media (max-width: 600px){
    width: calc(100% / 6);
  }
  @media (max-width: 410px){
    width: calc(100% / 5);
  }
  &:hover, &:focus {
    border: 5px solid #000000;
  }
  ${({ active }) => active === true && css`
    border: 5px solid #000000;
  `}
`;
const TitleMonthContainer = styled(TitleContainer)`
   @media (max-width: 550px) {
    padding: 30px 0;
  }
`;
const FlexContainer = styled(Flex)`
  justify-content: ${({ noCenter }) => noCenter ? 'space-between' : 'flex-start'};
`;
const CrossButton = styled(OptionCircle)`
  padding-right: ${({ padding }) => padding ? '12px' : '0px'};
  @media (max-width: 500px){
      padding: 0;
  }
`;
const MandatoryField = styled.span`
  font-family: ${MediumFont};
  color: red;
`;
export {MainContianer, SideMonthsContianer, CreateTasksContianer, Month, TaskHeader, TasksContianer, SingleTaskContainer, SaveTasks, SingleTaskHeader, TextArea, InputField, IconContainer, IconDisplay, TitleMonthContainer, FlexContainer, CrossButton, MandatoryField};