/* eslint-disable no-console,no-undef, camelcase, no-useless-escape */
import React, {Component} from 'react';
import moment from "moment";
import {MainContianer, SideMonthsContianer, CreateTasksContianer, Month} from './styles';
import {findMonth, themeHeader} from '../../utils/methods';
const CreateMonthlyTasks = React.lazy(() => import("./CreateMonthlyTasks/index"))
import {getViewMonthlyTaskIcons, addMonthlyTask, getAdminMonthlyTasks, getUserTaskStatus} from "../../redux/actions";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import Waiting from "../Waiting";
import { Suspense } from 'react';
class AdminMonthlyTasks extends Component{
  constructor(props) {
    super(props);
    this.state={
      date: moment().format('MMMM YYYY'),
      currentDate: moment().format('MMMM YYYY'),
      taskArray: [
        {
          taskName: '',
          introInformation: '',
          expandedMessage: '',
          addActionLink: '',
          linkAddress: '',
          iconId: '',
          iconPath: '',
          taskId: '',
          isUserTaken: ''
        }
      ],
      isDeleted: []
    }
  }
  componentDidMount() {
    const {companyInfo} = this.props;
    this.props.getViewMonthlyTaskIcons();
    let date = new Date();
    if(companyInfo) {
      this.props.getAdminMonthlyTasks(findMonth(date.getMonth()).toLowerCase(), date.getFullYear(), companyInfo.id);
    }
  }
  componentDidUpdate(prevProps) {
    const{ adminMonthlyTasks, deleteTaskStatus} = this.props;
    const{taskArray, isDeleted} = this.state;
    if(adminMonthlyTasks !== prevProps.adminMonthlyTasks){
      if(adminMonthlyTasks.length > 0) {
        let tasks = [];
        adminMonthlyTasks.map((data) => {
          let object = {
            taskName: data.title,
            introInformation: data.introduction,
            expandedMessage: data.description,
            addActionLink: data.action_link,
            linkAddress: data.link_address,
            iconPath: data.icon,
            iconId: data.icon_id,
            taskId: data.id,
            isUserTaken: data.is_user_task
          }
          tasks.push(object);
        })
        this.setState({taskArray: tasks});
      } else {
        let taskArray = [
          {
            taskName: '',
            introInformation: '',
            expandedMessage: '',
            addActionLink: '',
            linkAddress: '',
            iconId: '',
            iconPath: '',
            taskId: '',
            isUserTaken: 0
          }
        ]
        this.setState({taskArray: taskArray});
      }
    }
    if(deleteTaskStatus !== prevProps.deleteTaskStatus){
      let index = taskArray.findIndex((data) => data.taskId == deleteTaskStatus.task_id);
      if(deleteTaskStatus.task_status === 1){
        let task = taskArray;
        if(index !== -1) {task[index].isUserTaken = deleteTaskStatus.task_status;}
        this.setState({taskArray: task});
      } else{
        isDeleted.push(deleteTaskStatus.task_id);
        if(index !== -1) {taskArray.splice(index, 1);}
        this.setState({taskArray: taskArray, isDeleted: isDeleted});
      }
      if (typeof(this.MonthlyTask) !== 'undefined' && this.MonthlyTask !== null) {
        setTimeout(() => {
          this.MonthlyTask && this.MonthlyTask.scrollIntoView({block: 'start', behavior: 'smooth'});
        }, 500);
      }
    }
  }

  onChangeInput = (e, index) => {
    const { taskArray } = this.state;
    const fieldName = e.target.name;
    if(e.target.value !== ' ') {
      taskArray[index][`${fieldName}`] = e.target.value;
      this.setState({taskArray: taskArray});
    }
  }
  onChangeImage = (id, path, index) => {
    const { taskArray } = this.state;
    taskArray[index].iconPath = path;
    taskArray[index].iconId = id;
    this.setState({taskArray: taskArray});
  }
  addTasks = () => {
    const {taskArray} = this.state;
    let object = {
      taskName: '',
      introInformation: '',
      expandedMessage: '',
      addActionLink: '',
      linkAddress: '',
      iconId: '',
      iconPath: '',
      taskId: '',
      isUserTaken: 0
    }
    taskArray.push(object);
    this.setState({taskArray: taskArray});
  }
  deleteTask = (id, index) => {
    const {taskArray, isDeleted} = this.state;
    if(id !== ''){
      this.props.getUserTaskStatus(id);
    }else{
      taskArray.splice(index, 1);
      this.setState({taskArray: taskArray, isDeleted: isDeleted});
    }
  }
  setMonth = (month) =>{
    const {companyInfo} = this.props;
    let selectedYear = moment(this.state.date, 'MMMM YYYY').format('YYYY');
    this.setState({date: `${month} ${selectedYear}`});
    if (companyInfo) {
      this.props.getAdminMonthlyTasks(month.toLowerCase(), selectedYear, companyInfo.id);
    }
  }
  monthLists = () => {
    const{ date } = this.state;
    return themeHeader.map((data, index) => (
      <Month key={index} active={moment(date, "MMMM YYYY").format("MMMM") == data.month} onClick={() => this.setMonth(data.month)}>{data.month}</Month>
    ))};

  changeYear = (currentText) => {
    const {companyInfo} = this.props;
    const addOrSubtract = currentText == "Next Year" ? 1 : 0;
    const year = addOrSubtract ? moment(this.state.date, 'MMMM YYYY').add(1, 'year').format('MMMM YYYY') : moment(this.state.date, 'MMMM YYYY').subtract(1, 'year').format('MMMM YYYY');
    this.setState({date: year});
    if (companyInfo) {
      this.props.getAdminMonthlyTasks(moment(year, 'MMMM YYYY').format('MMMM'), moment(year, 'MMMM YYYY').format('YYYY'), companyInfo.id);
    }
  }
  saveMonthTask = () => {
    const{date, taskArray, isDeleted } = this.state;
    const {companyInfo} = this.props;
    let tasks = [];
    let companyId = companyInfo ? companyInfo.id : '';
    taskArray.map((data) => {
      let object = {
        title: data.taskName,
        introduction: data.introInformation,
        description: data.expandedMessage,
        icon_id: data.iconId,
        action_link: data.addActionLink,
        link_address: data.linkAddress,
        id: data.taskId
      }
      tasks.push(object);
    })
    let month = moment(date, "MMMM YYYY").format("MMMM").toLowerCase();
    let year = moment(date, "MMMM YYYY").format("YYYY");
    let object = {
      month: month,
      year: year,
      is_deleted: isDeleted,
      tasks: tasks,
      company_id: companyId
    }
    if (companyInfo) {
      this.props.addMonthlyTask(object, month, year, companyInfo.id);
    }
  }
  render() {
    const{date, currentDate, taskArray } = this.state;
    const currentText = (moment(date, "MMMM YYYY").format("YYYY") == moment(currentDate, "MMMM YYYY").format("YYYY")) ? "Next Year" : "Previous Year";
    return (
      <div ref={(MonthlyTask) => this.MonthlyTask = MonthlyTask}>
        <MainContianer>
          <SideMonthsContianer>
            <Month>{moment(date, "MMMM YYYY").format("YYYY")}</Month>
            {this.monthLists()}
            <Month onClick={()=>this.changeYear(currentText)}>{currentText}</Month>
          </SideMonthsContianer>
          {this.props.isLoading ? <Waiting /> :<CreateTasksContianer>
            <Suspense fallback={<Waiting/>}>
              <CreateMonthlyTasks
                onChangeInput={this.onChangeInput}
                addTasks={this.addTasks}
                deleteTask={this.deleteTask}
                taskArray={taskArray}
                date={date}
                monthlyTaskIcons={this.props.monthlyTaskIcons}
                onChangeImage={this.onChangeImage}
                saveTask={this.saveMonthTask}
              />
            </Suspense>
          </CreateTasksContianer>
          }
        </MainContianer>
      </div>
    );
  }
}
AdminMonthlyTasks.propTypes = {
  monthlyTaskIcons: PropTypes.array,
  getViewMonthlyTaskIcons: PropTypes.func,
  addMonthlyTask: PropTypes.func,
  getAdminMonthlyTasks: PropTypes.func,
  adminMonthlyTasks: PropTypes.array,
  isLoading: PropTypes.bool,
  getUserTaskStatus: PropTypes.func,
  deleteTaskStatus: PropTypes.object,
  companyInfo: PropTypes.object,
  companies: PropTypes.array,
}
const mapStateToProps = (state) => ({
  monthlyTaskIcons: state.companyDashboard.monthlyTaskIcons,
  adminMonthlyTasks: state.companyDashboard.adminMonthlyTasks,
  isLoading: state.companyDashboard.isLoading,
  deleteTaskStatus: state.companyDashboard.deleteTaskStatus
});
const mapDispatchToProps = (dispatch) => ({
  getViewMonthlyTaskIcons: () => dispatch(getViewMonthlyTaskIcons()),
  addMonthlyTask: (data, month, year, companyId) => dispatch(addMonthlyTask(data, month, year, companyId)),
  getAdminMonthlyTasks: (month, year, companyId) => dispatch(getAdminMonthlyTasks(month, year, companyId)),
  getUserTaskStatus: (id) => dispatch(getUserTaskStatus(id))
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminMonthlyTasks);